const menuSwitch = () => {
  const menuSwitchTabItemAllDOM = document.querySelectorAll<HTMLElement>(".js-top-menuSwitchTabItem");
  const courseItemAllDOM = document.querySelectorAll<HTMLElement>(".js-top-courseItem");

  menuSwitchTabItemAllDOM[0].classList.add("active");
  courseItemAllDOM[0].classList.add("active");

  for (let i = 0; i < menuSwitchTabItemAllDOM.length; i++) {
    menuSwitchTabItemAllDOM[i].setAttribute("data-num", String(i));
    menuSwitchTabItemAllDOM[i].addEventListener("click", function () {
      const menuSwitchTabItemActiveDOM = document.querySelector<HTMLElement>(".js-top-menuSwitchTabItem.active");
      const courseItemActiveDOM = document.querySelector<HTMLElement>(".js-top-courseItem.active");

      menuSwitchTabItemActiveDOM?.classList.remove("active");
      courseItemActiveDOM?.classList.remove("active");

      // getAttributeがnullを持っているため、一度変数へ代入
      let elementNumClicked = Number(this.getAttribute("data-num"));
      this.classList.add("active");
      if(elementNumClicked || elementNumClicked == 0) courseItemAllDOM[elementNumClicked].classList.add("active");
    });
  }
};

export default menuSwitch;
