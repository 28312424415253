const humbargerDom = document.querySelector<HTMLElement>('.bar')
const headerNavLinkDomArr = document.querySelectorAll<HTMLElement>('.js-header-navLink')
const humbarger = () => {
  if(humbargerDom) {
    humbargerDom.addEventListener( 'click', () => {
      humbargerDom.classList.toggle('is-active')
    })
  }
  if(headerNavLinkDomArr) {
    headerNavLinkDomArr.forEach(el => {
      el.addEventListener('click', () => {
        humbargerDom?.classList.remove('is-active')
      })
    });
  }
}

export default humbarger