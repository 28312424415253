let body = document.querySelector<HTMLElement>('body')
const designModalNum = ['1', '2', '3', '4']

const designModalOpen = () => {
  designModalNum.forEach(el => {
    document.querySelector<HTMLElement>('#js-design-modalTrigger-' + el)?.addEventListener(
      'click', () => {
        document.querySelector<HTMLElement>('#js-design-modal-' + el)?.classList.add('active')
        body?.classList.add('js-noscroll')
      }
    )
  });
}


const designModalClose = () => {
  designModalNum.forEach(el => {
    document.querySelector<HTMLElement>('#js-design-modalBg-' + el)?.addEventListener(
      'click', () => {
        document.querySelector<HTMLElement>('#js-design-modal-' + el)?.classList.remove('active')
        body?.classList.remove('js-noscroll')
      }
      )
    });
    designModalNum.forEach(el => {
      document.querySelector<HTMLElement>('#js-design-modalCancelBtn-' + el)?.addEventListener(
        'click', () => {
        document.querySelector<HTMLElement>('#js-design-modal-' + el)?.classList.remove('active')
        body?.classList.remove('js-noscroll')
      }
    )
  });
}

const designFunc = () => {
  designModalOpen()
  designModalClose()
}

export default designFunc