import Swiper, {Autoplay, Pagination, SwiperOptions} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

/**
 * Todo：Naming
 * 大牟田・柳川の旅プランページで使用するスクリプト
 */
const travelplanOmutaYanagawa = () => {
    document.addEventListener('DOMContentLoaded', function() {
        /**
         * ページ表示のアニメーションクラス
         */
        document.querySelector<HTMLElement>('body')?.classList.add('add')

        /**
         * 詳細情報のアコーディオン
         * Memo：「closest」を使用した場合に型安全を強制的に指定している
         */
         const targetSelectors = document.querySelectorAll('.spot_infomationTitle')
         targetSelectors.forEach(target => {
            target.addEventListener('click', (e) => {
                let target = e.target as HTMLElement | null;
                if(target && target.closest('.spot_infomation')) {
                    target.closest('.spot_infomation')!.classList.toggle('open')
                }
            })
        });

        /**
         * 各セクションのスライダー
         */
        const customSlider = document.querySelectorAll('.spot_image_slider');
        Swiper.use([Autoplay,Pagination])
        for(let i=0; i< customSlider.length; i++ ) {
          customSlider[i].classList.add("swiper-container-" + i);

          let option: SwiperOptions = {
            slidesPerView: "auto",
            centeredSlides: true,
            loop: true,
            autoplay: {
              delay: 4000,
              disableOnInteraction: false,
            },
          };
          if (customSlider[i].querySelector(".swiper-pagination")) {
            const element: HTMLElement | null =
              customSlider[i].querySelector(".swiper-pagination");
            option.pagination = {
              el: element,
              clickable: true,
            };
          }
          //@ts-ignore TS6133: 'slider' is declared but its value is never read.
          const slider = new Swiper(".swiper-container-" + i, option);
        }
    });
}

export default travelplanOmutaYanagawa