const seasonArr = ["spring", "summer", "autumn", "winter"];
const menuArr = ["zensai", "fish", "meat", "dessert"];
let body = document.querySelector<HTMLElement>("body");

// ▼▼▼ child menu modal ▼▼▼
const menuOfChildOpen = () => {
  const trigger = document.querySelector<HTMLElement>("#js-top-menuOfChild-trigger");
  const modalEl = document.querySelector<HTMLElement>("#js-top-menuOfChild");

  trigger?.addEventListener("click", () => {
    modalEl?.classList.add("active");
    body?.classList.add("js-noscroll");
  });
};

const menuOfChildClose = () => {
  const triggerCancel = document.querySelector<HTMLElement>("#js-modalBg-menuOfChild");
  const triggerCancel2 = document.querySelector<HTMLElement>("#js-cancelBtn-menuOfChild");
  const modalEl = document.querySelector<HTMLElement>("#js-top-menuOfChild");
  const triggerCancelArr = [triggerCancel, triggerCancel2]

  triggerCancelArr.forEach(el => {
    el?.addEventListener("click", () => {
      modalEl?.classList.remove("active");
      body?.classList.remove("js-noscroll");
    });
  })
};
// ▲▲▲ child menu modal ▲▲▲

// ▼▼▼ menu modal ▼▼▼
const menuPinOpen = () => {
  menuArr.forEach((menu) => {
    seasonArr.forEach((season) => {
      document.querySelector<HTMLElement>("#js-pin-" + season + "-" + menu)?.addEventListener("click", () => {
        document.querySelector<HTMLElement>("#js-modal-" + menu + "-" + season)?.classList.add("active");
        body?.classList.add("js-noscroll");
      });
    });
  });
};

const menuPinClose = () => {
  menuArr.forEach((menu) => {
    seasonArr.forEach((season) => {
      document.querySelector<HTMLElement>("#js-modalBg-" + menu + "-" + season)?.addEventListener("click", () => {
        document.querySelector<HTMLElement>("#js-modal-" + menu + "-" + season)?.classList.remove("active");
        body?.classList.remove("js-noscroll");
      });
      document.querySelector<HTMLElement>("#js-cancelBtn-" + menu + "-" + season)?.addEventListener("click", () => {
        document.querySelector<HTMLElement>("#js-modal-" + menu + "-" + season)?.classList.remove("active");
        body?.classList.remove("js-noscroll");
      });
    });
  });
};
// ▲▲▲ menu modal ▲▲▲

// ▼▼▼ allergy modal ▼▼▼
const allergyOpen = () => {
  seasonArr.forEach((season) => {
    document.querySelector<HTMLElement>("#js-triger-modalAllergy-" + season)?.addEventListener("click", () => {
      document.querySelector<HTMLElement>("#js-modal-allergy-" + season)?.classList.add("active");
      body?.classList.add("js-noscroll");
    });
  });
};

const allergyClose = () => {
  seasonArr.forEach((season) => {
    document.querySelector<HTMLElement>("#js-modalBg-allergy-" + season)?.addEventListener("click", () => {
      document.querySelector<HTMLElement>("#js-modal-allergy-" + season)?.classList.remove("active");
      body?.classList.remove("js-noscroll");
    });
    document.querySelector<HTMLElement>("#js-cancelBtn-allergy-" + season)?.addEventListener("click", () => {
      document.querySelector<HTMLElement>("#js-modal-allergy-" + season)?.classList.remove("active");
      body?.classList.remove("js-noscroll");
    });
  });
};
// ▲▲▲ allergy modal ▲▲▲

// ▼▼▼ direction modal ▼▼▼
const modalTriggerDirectionDom = document.querySelector<HTMLElement>("#js-trigger-modalDirection");
const modalDirectionDom = document.querySelector<HTMLElement>("#js-modal-direction");
const modalDirectionBgDOM = document.querySelector<HTMLElement>("#js-modalBg-direction");
const modalDirectionCancelDOM = document.querySelector<HTMLElement>("#js-cancelBtn-direction");

const directionOpen = () => {
  modalTriggerDirectionDom?.addEventListener("click", () => {
    modalDirectionDom?.classList.add("active");
    body?.classList.add("js-noscroll");
  });
};

const directionClose = () => {
  modalDirectionBgDOM?.addEventListener("click", () => {
    modalDirectionDom?.classList.remove("active");
    body?.classList.remove("js-noscroll");
  });
  modalDirectionCancelDOM?.addEventListener("click", () => {
    modalDirectionDom?.classList.remove("active");
    body?.classList.remove("js-noscroll");
  });
};
// ▲▲▲ direction modal ▲▲▲

// ▼▼▼ seat modal ▼▼▼
const modalTriggerSeatDom = document.querySelector<HTMLElement>("#js-triger-seat-modal");
const modalSeatDom = document.querySelector<HTMLElement>("#js-modal-seat");
const modalSeatBgDOM = document.querySelector<HTMLElement>("#js-modalBg-seat");
const modalSeatCancelDOM = document.querySelector<HTMLElement>("#js-cancelBtn-seat");

const seatOpen = () => {
  modalTriggerSeatDom?.addEventListener("click", () => {
    modalSeatDom?.classList.add("active");
    body?.classList.add("js-noscroll");
  });
};

const seatClose = () => {
  modalSeatBgDOM?.addEventListener("click", () => {
    modalSeatDom?.classList.remove("active");
    body?.classList.remove("js-noscroll");
  });
  modalSeatCancelDOM?.addEventListener("click", () => {
    modalSeatDom?.classList.remove("active");
    body?.classList.remove("js-noscroll");
  });
};
// ▲▲▲ seat modal ▲▲▲

const modalFunc = () => {
  allergyOpen();
  allergyClose();
  directionOpen();
  directionClose();
  menuOfChildOpen();
  menuOfChildClose();
  menuPinOpen();
  menuPinClose();
  seatOpen();
  seatClose();
};

export default modalFunc;
