import Swiper, { Navigation } from 'swiper';

const swiperCal = () => {
  new Swiper(".top-calSliderWrap", {
    modules: [Navigation],
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
}

export default swiperCal