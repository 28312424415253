const headerLogoLink = document?.querySelector<HTMLElement>("#js-logo-link")
const headerFitstLogo = document?.querySelector<HTMLElement>("#js-logo-spFirst")
const headerSecondLogo = document?.querySelector<HTMLElement>("#js-logo-spSecond")
const headerNavReserve = document?.querySelector<HTMLElement>("#js-header-navItem-reserve")

const spHeader = () => {
  // 監視する
  // if(mainContents) observer.observe(mainContents);
  window.addEventListener('scroll', function () {
    let scrollTop = window.pageYOffset
    if(scrollTop > 200) {
      headerNavReserve?.classList.add('view')
      headerFitstLogo?.classList.remove('view')
      headerSecondLogo?.classList.add('view')
      headerLogoLink?.classList.add('second')
    } else {
      headerNavReserve?.classList.remove('view')
      headerFitstLogo?.classList.add('view')
      headerSecondLogo?.classList.remove('view')
      headerLogoLink?.classList.remove('second')
    }
  })
}

export default spHeader